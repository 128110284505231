<template>
  <div class="page" v-loading="loading">
    <div class="container">
      <div class="header">
        <div class="icon"></div>
        <!-- <div class="title">{{ $t('meetingDetailPage.title') }}</div> -->
        <!-- <div class="search">
          <el-button class="button" type="primary" @click="handleCreate">创建主持人</el-button>
        </div> -->
        <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/meeting' }">{{ $t('meetingPage.title') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t('meetingDetailPage.title') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="subtitle">
          <!-- <div class="icon"></div> -->
          <div class="title">会议信息</div>
        </div>
        <div class="host-info">
          <el-descriptions
            :column="2"
            border
          >
            <el-descriptions-item>
              <template #label>
                会议主题
              </template>
              {{ detail.subject }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                会议号码
              </template>
              {{ detail.meetingCode }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                开始时间
              </template>
              {{ moment.unix(Number(detail.startTime)).format('YYYY-MM-DD HH:mm:ss') }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                结束时间
              </template>
              {{ moment.unix(Number(detail.endTime)).format('YYYY-MM-DD HH:mm:ss') }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                手机号/用户id
              </template>
              {{ detail.theHosts.userId || '暂无数据' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                是否开启等候室
              </template>
              <span v-if="detail.settings && detail.settings.autoInWaitingRoom">开启</span>
              <span v-else>关闭</span>
            </el-descriptions-item>
             <el-descriptions-item>
              <template #label>
                入会链接
              </template>
              <el-button v-if="detail.joinUrl" type="primary" link @click="handleCopy(detail.joinUrl)" style="margin-right:5px;">复制链接</el-button>
              <span class="link-url" v-if='detail.joinUrl' @click="handleOpen(detail.joinUrl)">{{ detail.joinUrl || '暂无数据' }}</span>
              <span v-else>暂无数据</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="subtitle">
          <!-- <div class="icon"></div> -->
          <!-- <div class="title">参会嘉宾列表</div> -->
          <!-- <el-button class="button" type="primary">导出参会成员列表</el-button> -->
        </div>
        <div>
          <!-- <el-table
            class="table"
            :data="tableData"
            header-cell-class-name="header"
            style="width: 100%"
          >
            <el-table-column prop="date" label="序号" align="center" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="date" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="name" label="电话" align="center">
            </el-table-column>
            <el-table-column
              label="操作"
              width="160"
              align="center"
            >
              <template v-slot="scope">
                <el-button type="primary" link>编辑</el-button>
                <el-button type="danger" link @click="handleDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table> -->
          <!-- <common-page
            v-model:current-page="pageObj.page"
            v-model:page-size="pageObj.size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="pageObj.total"
          >
          </common-page> -->
        </div>
      </div>
    </div>
    <!-- 创建和修改弹窗 -->
    <el-dialog
      ref="createDialog"
      class="create-dialog"
      :show-close="false"
      :title="createDialog.title"
      :close-on-click-modal = false
      v-model="createDialog.show"
      width="40vw"
      center
    >
      <div class="main">
        <el-form
          ref="createForm"
          label-position="right"
          label-width="120px"
          :model="createForm"
          :rules="createRules"
          style="width:100%"
        >
          <el-form-item prop="name" label="姓名：">
            <el-input v-model="createForm.name"></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="手机号码：">
            <el-input v-model="createForm.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelCreate">取 消</el-button>
          <el-button type="primary" @click="submitCreateForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import commonPage from '@/components/public/pagination.vue'
import moment from 'moment'
import { handleCopy } from '@/utils/utils'
import { meetingDetail } from '@/api/api'
export default {
  components: {
    // commonPage
  },
  data () {
    return {
      moment: moment,
      userList: [],
      tableData: [
      ],
      pageObj: {
        page: 1,
        size: 10,
        total: 0
      },
      createDialog: {
        title: '创建主持人',
        show: false
      },
      createForm: {
        name: '',
        phone: ''
      },
      createRules: {
        name: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ]
      },
      detail: {
        currentHosts: {},
        theHosts: {}
      },
      loading: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.loading = true
      // var detail = JSON.parse(sessionStorage.getItem('meeting_detail') || '{}')
      // detail.theHosts = ''
      // if (detail.currentHosts && detail.currentHosts.length > 0) {
      //   detail.theHosts = detail.currentHosts[0]
      // }
      // if (detail.hosts && detail.hosts.length > 0) {
      //   detail.theHosts = detail.hosts[0]
      // }
      const params = {
        meetingId: this.$route.query.id,
        userId: this.$route.query.host
      }
      meetingDetail(params).then(res => {
        if (res.code === 0) {
          const detail = res.data
          detail.theHosts = ''
          if (detail.currentHosts && detail.currentHosts.length > 0) {
            detail.theHosts = detail.currentHosts[0]
          }
          if (detail.hosts && detail.hosts.length > 0) {
            detail.theHosts = detail.hosts[0]
          }
          this.detail = detail
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleCurrentChange (val) {
      console.log(val)
    },
    handleSizeChange (val) {
      this.pageObj.page = 1
      console.log(val)
    },
    handleCreate () {
      this.createDialog.title = '创建主持人'
      this.createDialog.show = true
    },
    resetDialog () {
      this.createDialog = {
        title: '创建主持人',
        show: false
      }
    },
    submitCreateForm () {
      this.createForm.show = false
    },
    cancelCreate () {
      this.$refs.createForm.resetFields()
      this.createDialog.show = false
    },
    handleDetail (item) {
      this.$router.push('/meetingDetail')
    },
    handleDel (item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
        })
        .catch(() => {
        })
    },
    handleCopy (text) {
      handleCopy(text)
    },
    handleOpen (url) {
      if (!url) return
      window.open(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 0 auto;
  width: 1200px;
  background: white;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  .icon {
    width: 6px;
    height: 30px;
    background: $colorGreen;
    margin-right: 10px;
  }
  .title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .search {
    margin-left: auto;
  }
}
.subtitle {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: $subTitleColor;
  .icon {
    width: 4px;
    height: 24px;
    background: $subTitleColor;
    margin-right: 10px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
  }
}
.host-info {
  margin-bottom: 40px;
}
.create-dialog {
  .main {
    display: flex;
    justify-content: center;
  }
}
.link-url {
  cursor: pointer;
  &:hover {
    color: $colorBlue;
    text-decoration: underline;
  }
}
</style>
